<template>
  <div class="advertising">
    <el-card
      v-loading="loading"
      class="left"
      :style="{ width: leftWidth }"
      width="2300"
    >
      <div
        :class="leftWidth == '230px' ? 'el-icon-back a' : 'el-icon-right a'"
        @click="show"
      ></div>
      <el-tree
        :accordion="true"
        v-if="leftWidth == '230px'"
        style="font-size: 13px"
        :data="children"
        :render-content="renderContent"
        @node-expand='clickTree'
        node-key="id"
      >
        <p
          @click="clickTree(data)"
          class="custom-tree-node icons"
          slot-scope="{ node, data }"
        >
          <span
            style="font-size: 14px; color: #999999; margin-right: 5px"
            v-if="data.targetType"
            >({{ data.targetType }})</span
          >
          <span
            style="font-size: 14px; color: #999999; margin-right: 5px"
            v-if="data.targetingType"
            >({{ data.targetingType }})</span
          >
          <span v-if="data.state != ''" style="margin: 0 3px 0 -3px">
            <span
              v-if="data.state == 'enabled'"
              class="iconfont icon-duihao"
              style="color: #1afa29; font-weight: 600"
            ></span>
            <span
              v-if="data.state == 'paused'"
              class="iconfont icon-zantingtingzhi"
              style="color: #ff0000; font-weight: 600; font-size: 14px"
            ></span>
            <span
              v-if="data.state == 'archived'"
              class="iconfont icon-guidang"
              style="font-weight: 600; font-size: 14px"
            ></span>
          </span>
          <span>{{ data.value }}</span>
          <span v-if="data.size" style="margin-left: 4px"
            >({{ data.size }})</span
          >
        </p>
      </el-tree>
      <el-tree
        :accordion="true"
        v-if="leftWidth == '230px'"
        style="font-size: 13px"
        :data="adPortfoliosTrees"
        node-key="id"
      >
        <p
          disabled
          @click="clickGroup(data)"
          class="custom-tree-node icons"
          slot-scope="{ node, data }"
        >
          <span
            style="font-size: 14px; color: #999999"
            v-if="data.targetingType"
            >({{ data.targetingType }})</span
          >
          <span v-if="data.state != ''" style="margin: 0 3px 0 -3px">
            <span
              v-if="data.state == 'enabled'"
              class="iconfont icon-duihao"
              style="color: #1afa29; font-weight: 600"
            ></span>
            <span
              v-if="data.state == 'paused'"
              class="iconfont icon-zantingtingzhi"
              style="color: red; font-weight: 600; font-size: 14px"
            ></span>
          </span>
          <span>{{ data.value }}</span>
          <span style="margin-left: 4px" v-if="data.size"
            >({{ data.size }})</span
          >
        </p>
      </el-tree>
    </el-card>
    <el-card class="right">
      <!-- <keep-alive> -->
      <router-view>
        <!-- 这里是会被缓存的视图组件 -->
      </router-view>
      <!-- </keep-alive> -->
    </el-card>
  </div>
</template>

<script>
import { getLeft, getDetail } from "@/api/Advertising/index";
// import { min } from 'moment';
export default {
  name: "adv",
  data() {
    return {
      leftWidth: "230px",
      loading: false,
      adPortfoliosTrees: [],
      children: [],
      profileId: ""
    };
  },
  watch: {
    "$store.state.profileId": function() {
      this.profileId = this.$store.state.profileId;
      this.getLeftInfo();
    },
    "$store.state.num": function() {
      this.profileId = this.$store.state.profileId;
      this.getLeftInfo();
    },
    deep: true,
    immediate: true
  },
  created() {
    this.getLeftInfo();
  },
  beforeDestroy() {
    this.$store.commit("setProfileId", "");
  },
  methods: {
    clickGroup(data) {
      if (data.value == "广告组合") return;
      this.$router.replace({
        path: data.url,
        query: {
          type: data.type,
          value: data.value,
          portfoliosId: data.type == "getList" ? data.id : null,
          state: data.campaignStatus ? data.campaignStatus : null,
          profileId: this.$store.state.profileId
        }
      });
    },
    clickTree(data) {
      this.$router.replace({
        path: data.url,
        query: {
          type: data.type,
          state: data.state,
          value: data.value,
          profileId: this.$store.state.profileId,
          campaignId: data.campaignId ? data.campaignId : null,
          id: data.id || data.groupId
        }
      });
      if (
        data.children[0].value == 'loading' &&
        data.url == "/group" &&
        data.type == "getOne"
      ) {
        this.loading = true;
        getDetail({adGroupId: data.id},this.$store.state.profileId).then(res => {
          console.log(res);
          this.loading = false;
          if(res.data.data.length!=0){
            this.$set(data,'children',[])
            data.children=[...res.data.data]
          }
        });
      }
    },
    show() {
      if (this.leftWidth == "0px") {
        this.leftWidth = "230px";
      } else if (this.leftWidth == "230px") {
        this.leftWidth = "0px";
      }
    },
    getLeftInfo() {
      this.loading = true;
      getLeft(this.profileId).then(res => {
        this.loading = false;
        this.adPortfoliosTrees = [res.data.data.adPortfoliosTrees];
        this.children = res.data.data.children;
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.advertising {
  display: flex;
  .left {
    margin-right: 15px;
    position: relative;
    min-height: 100px;
    overflow: visible;
    // z-index: 1;
    padding: 0;
    ::v-deep .el-card__body {
      padding: 15px 0 0 10px;
    }
  }
  .right {
    background: white;
    flex: 1;
    z-index: 0.7;
  }
}
.a {
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: absolute;
  right: -15px;
  color: grey;
  z-index: 2;
}
.icons {
  display: flex;
  align-items: center;
}
</style>