import request from '@/utils/request'

export function getLeft(params){
    return request({
        url:'/api/amzAdPortfolios/getLeftNavigationBar',
        method:'get',
        headers:{
            'profileId':params
        }
    });
}

export function getDetail(params,id) {
    return request({
        url:'/api/amzAdPortfolios/getDetail',
        method:'get',
        params,
        headers:{
            profileId:id
        }
    });
}